/*      Checkbox and radio         */
.checkbox,
.radio {
  margin-bottom: 12px;
}

.checkbox label,
.radio label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  margin-bottom: 0;
}

.checkbox label::before,
.checkbox label::after{
  font-family: 'FontAwesome';
  content: "\f096";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  cursor: pointer;
  line-height: 20px;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 0px;
  color: $medium-gray;
  transition: color 0.2s linear;
  padding: 1px;
}

 .checkbox label::after{
  content: "";
  text-align: center;
  opacity: 1;
  left: -1px;
  color: $medium-gray;
}

.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  opacity: 0;
  margin-left: 0;
}

.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="checkbox"]:not(:disabled):hover + label::after{
    font-family: 'FontAwesome';
    content: "\f046";
}

.checkbox input[type="checkbox"]:checked + label::after{
    color: $color-azure;
}



.checkbox input[type="checkbox"]:not(:disabled):hover + label::before,
.checkbox input[type="checkbox"]:checked + label::before{
    opacity: 0;
}

.checkbox input[type="checkbox"]:disabled + label,
.radio input[type="radio"]:disabled + label,
.checkbox input[type="checkbox"]:disabled:checked + label::after {
    color: $medium-gray;
}

.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="checkbox"]:disabled + label::after{
    cursor: not-allowed;
}

.checkbox input[type="checkbox"]:disabled + label,
.radio input[type="radio"]:disabled + label{
    cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  padding-left:0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.radio label::before,
.radio label::after {
  font-family: 'FontAwesome';
  content: "\f10c";
  font-size: 20px;
  height: 20px;
  width: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  line-height: 20px;
  left: 0;
  top: 0;
  color: $medium-gray;
  padding: 1px;
  transition: color 0.2s linear;
}

.radio input[type="radio"]:not(:disabled):hover + label::after,
.radio input[type="radio"]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\f192";
    color: $medium-gray;
}

.radio input[type="radio"]:checked:not(:disabled):hover + label::after,
.radio input[type="radio"]:checked + label::after{
    color: $color-azure;
}


.radio input[type="radio"]:disabled + label {
  color: #ddd;
}

.radio input[type="radio"]:disabled + label::before,
.radio input[type="radio"]:disabled + label::after {
  color: #ddd;
}

.radio.radio-inline {
  margin-top: 0;
}

// .radio-primary input[type="radio"] + label::after {
//   background-color: #428bca;
// }
//
// .radio-primary input[type="radio"]:checked + label::before {
//   border-color: #428bca;
// }
//
// .radio-primary input[type="radio"]:checked + label::after {
//   background-color: #428bca;
// }
//
// .radio-danger input[type="radio"] + label::after {
//   background-color: #d9534f;
// }
//
// .radio-danger input[type="radio"]:checked + label::before {
//   border-color: #d9534f;
// }
//
// .radio-danger input[type="radio"]:checked + label::after {
//   background-color: #d9534f;
// }
//
// .radio-info input[type="radio"] + label::after {
//   background-color: #5bc0de;
// }
//
// .radio-info input[type="radio"]:checked + label::before {
//   border-color: #5bc0de;
// }
//
// .radio-info input[type="radio"]:checked + label::after {
//   background-color: #5bc0de;
// }
//
// .radio-warning input[type="radio"] + label::after {
//   background-color: #f0ad4e;
// }
//
// .radio-warning input[type="radio"]:checked + label::before {
//   border-color: #f0ad4e;
// }
//
// .radio-warning input[type="radio"]:checked + label::after {
//   background-color: #f0ad4e;
// }
//
// .radio-success input[type="radio"] + label::after {
//   background-color: #5cb85c;
// }
//
// .radio-success input[type="radio"]:checked + label::before {
//   border-color: #5cb85c;
// }
//
// .radio-success input[type="radio"]:checked + label::after {
//   background-color: #5cb85c;
// }





/* ============================================================
 * bootstrapSwitch v1.3 by Larentis Mattia @spiritualGuru
 * http://www.larentis.eu/switch/
 * ============================================================
 * Licensed under the Apache License, Version 2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 * ============================================================ */
.has-switch {
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    line-height: 1.72222;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 60px;

   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   -o-user-select: none;
   user-select: none;

    /*   this code is for fixing safari bug with hidden overflow for border-radius   */
    -webkit-mask: url('/img/mask.png') 0 0 no-repeat;
    -webkit-mask-size: 60px 24px;
    mask: url('/img/mask.png') 0 0 no-repeat;
}
.has-switch.deactivate {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}
.has-switch.deactivate label,
.has-switch.deactivate span {
  cursor: default !important;
}
.has-switch > div {
   position: relative;
   top: 0;
   width: 100px;
}
.has-switch > div.switch-animate {
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}
.has-switch > div.switch-off {
  left: -35px;
}

.has-switch > div.switch-on {
  left: 0;
}
.has-switch > div label {
  background-color: #FFFFFF;
  @include icon-gradient (rgba(255,255,255,1), rgba(241,241,242,1));

  box-shadow: 0 1px 1px #FFFFFF inset, 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.has-switch input[type=checkbox] {
  display: none;
}
.has-switch span {
/*     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset; */
    cursor: pointer;
    float: left;
    font-size: 11px;
    font-weight: 400;
    height: 24px;
    line-height: 15px;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 5px;
    position: relative;
    text-align: center;
    text-indent: -10px;
    width: 50%;
    z-index: 1;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.has-switch span.switch-left {
    background-color: $info-color;
    border-left: 1px solid rgba(0, 0, 0, 0);
    border-radius: 30px 0 0 30px;
    color: #FFFFFF;
}
.has-switch .switch-off span.switch-left{
    background-color: $medium-gray;
}
.has-switch span.switch-right {
  border-radius: 0 30px 30px 0;
  background-color: $info-color;
  color: #ffffff;
  text-indent: 1px;
}
.has-switch .switch-off span.switch-right{
    background-color: $medium-gray;
}

.has-switch label {
    border-radius: 12px;
    float: left;
    height: 22px;
    margin: 1px -13px;
    padding: 0;
    position: relative;
    transition: all 0.25s ease-out 0s;
    vertical-align: middle;
    width: 22px;
    z-index: 100;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.has-switch .switch-on .fa-check:before{
    margin-left: 10px;
}
.has-switch:hover .switch-on label{
    margin: 1px -17px;
    width: 26px;
}
.has-switch:hover .switch-off label{
    margin: 1px -13px;
    width: 26px;
}
